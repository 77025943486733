import { Box, Button, Flex, FormControl, FormLabel, Input, Select, Text, VStack, Textarea } from "@chakra-ui/react";
import { filter, find, flatMap, map, sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorChecker from '../common/ErrorChecker';
import { getStudentMetaKeys, getAdmissionFormRequestAction } from '../redux/actions';
import STATUS, { printHelper, PRINT_TYPE, META_KEY_TYPES } from '../Constants'
import { CustomDatePicker } from '../helper';

export default function CustomEnquiryForm({ enquiry, school}) {
    const dispatch = useDispatch()
    const [selectedStandard, setSelectedStandard] = useState()
    const school_id = school.id
    // const counsellors = filter(school.staffList, user => checkRoles(map(user.user.data.role, r => r.name), [ROLES.COUNSELLOR]))
    const {
        statesList,
        classes,
        studentSectionMetaKeys,
        getStudentSectionsWithMetaKeysStatus,
        admissionFormStatus
    } = useSelector(state => ({
        classes: state.standards,
        statesList: state.states,
        getStudentSectionsWithMetaKeysStatus: state.getStudentSectionsWithMetaKeysStatus,
        studentSectionMetaKeys: state.studentSectionMetaKeys,
        admissionFormStatus: state.admissionForm,
    }));

    const enquiries = useSelector(state => state.EnquiriesListReducer)
    useEffect(() => {
        dispatch(getStudentMetaKeys(school_id))
    }, [])



    const handleSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        formData.set("school_id", school_id)
        dispatch(getAdmissionFormRequestAction(formData));
    }

    const printEnquiryForm = (data) => {
        printHelper(PRINT_TYPE.CUSTOM_ENQUIRY_FORM, { data, school, studentSectionMetaKeys })
    }

    let count = -1

    const resetForm = () => {
        document.getElementById('custom-enquiry-form').reset();
    }

    return (

        <Box>
            <Box textAlign='right' >
                <Button colorScheme='blue' size='sm' onClick={() => printHelper(PRINT_TYPE.ADMISSION_FORM_CUSTOM, { enquiry: {}, school: school, studentSectionMetaKeys })}>Print Empty Admission Form</Button>
            </Box>
            <form id='custom-enquiry-form' onSubmit={handleSubmit}>
                <ErrorChecker status={getStudentSectionsWithMetaKeysStatus}>
                    {getStudentSectionsWithMetaKeysStatus == STATUS.SUCCESS ?

                        <VStack w="100%" align="start">
                            {map(studentSectionMetaKeys, sec => (
                                <Box key={sec.id} pb={4} w={"100%"}>
                                    <Text fontSize={18} fontWeight="semibold" px='8px' py='4px' mt='8px' bgColor={'gray.100'} color="gray.600">{sec.title}</Text>
                                    <Flex px='12px' w="100%" flexWrap={"wrap"} color='gray.600'>
                                        {map(filter(sortBy(sec.studentMetaKeys?.data, [function (o) { return o.order; }]), d => d.enquiry_form_visible), (key, i) => {
                                            if (!key.default_key)
                                                count += 1
                                            const name = key.default_key ? key.default_key : "EnquiryMetaData[" + count + "][value]"
                                            const width = key.config?.enquiry_row_width === 1 ? "100%" : key.config?.enquiry_row_width === 2 ? "50%" : key.config?.enquiry_row_width === 3 ? "33.33%" : "33.33%"
                                            const required = key.config?.enquiry_form_required
                                            // const multiValues = find(data?.metaData?.data, d => d.student_meta_key_id === key.id)
                                            // const defaultValue = key.default_key ?  data?.[key.default_key]) : multiValues?.mult_values ? multiValues.mult_values : multiValues?.value
                                            return (
                                                key.key === "Standard" ?
                                                    <FormControl key={key.id} pr={5} mt={3} w={width} isRequired={required} >
                                                        <FormLabel>{key.key}</FormLabel>
                                                        <Select size={"sm"} name={name} placeholder={"Select " + key.key} onChange={(e) => setSelectedStandard(e.target.value)}>
                                                            {classes.map(standard => (
                                                                <option key={standard.id} value={standard.id}>{standard.name}</option>
                                                            ))}
                                                        </Select>
                                                    </FormControl>

                                                    : key.key === "State" ?
                                                        <FormControl key={key.id} pr={5} mt={3} w={width} isRequired={required} >
                                                            <FormLabel>{key.key}</FormLabel>
                                                            <Select size={"sm"} name={name} placeholder={"Select " + key.key} onChange={(e) => setSelectedState(e.target.value)}>
                                                                {statesList.map(st => (
                                                                    <option key={st.id} value={st.id}>{st.name}</option>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        : key.key === "Origin" ?
                                                            <Input type="hidden" size={"sm"} name={name} value={'2'} />
                                                            : key.key === "Counsellor" ?
                                                                <div></div>
                                                                :
                                                                <>
                                                                    {key.default_key ?
                                                                        null
                                                                        :
                                                                        <Input type="hidden" name={'EnquiryMetaData[' + count + '][StudentMetaKey_id]'} value={key.id} />
                                                                    }
                                                                    <CustomFormInput metaKey={key} i={i} width={width} name={name} school_id={school_id} required={required} />
                                                                </>
                                            )
                                        })}
                                    </Flex>
                                </Box>
                            ))}


                        </VStack>
                        : null}

                </ErrorChecker>

                <Input type="hidden" size={"sm"} name={'origin'} value='2' />

                <Flex w="100%" justifyContent={"space-between"}>
                    <Button type="submit" colorScheme="blue">
                        Submit
                    </Button>
                    {admissionFormStatus == STATUS.SUCCESS ?
                        <span>
                            <Button onClick={() => printHelper(PRINT_TYPE.ADMISSION_FORM_CUSTOM, { enquiry: enquiry, school: school, studentSectionMetaKeys })} colorScheme="blue">
                                Print Form
                            </Button>&nbsp;&nbsp;
                            <Button colorScheme="green" onClick={resetForm}>
                                Reset
                            </Button>
                        </span>
                        : null
                    }
                </Flex>

            </form>
        </Box>


    )
}

function CustomFormInput({ metaKey, width, name, required }) {
    const [multipleChoiceValues, setMultipleChoiceValues] = useState(false)
    const [multipleSelectValues, setMultipleSelectValues] = useState([])
    const [dateField, setDateField] = useState()

    return (
        metaKey ?
            <FormControl key={metaKey.id} pr={5} mt={3} w={width} isRequired={required} >
                <FormLabel>{metaKey.key}</FormLabel>
                {
                    metaKey.data_type === META_KEY_TYPES.TEXT ?
                        metaKey.config?.inputType === "textarea" ?
                            <Textarea maxLength={metaKey.config?.textLimit} required={required} type="text" size={"sm"} name={name} placeholder={metaKey.key} />
                            :
                            <Input required={required} type={metaKey.config?.input_field_type || "text"} maxLength={metaKey.config?.textLimit} size={"sm"} name={name} placeholder={metaKey.key} />
                        : metaKey.data_type === META_KEY_TYPES.DROPDOWN ?
                            <Select name={name} size={"sm"} w="100%" placeholder="select">
                                {map(metaKey.config?.options, opt => (
                                    <option value={opt}>{opt}</option>
                                ))}
                            </Select>
                            : metaKey.data_type === META_KEY_TYPES.MULTIPLE_CHOICE ?
                                <>
                                    {metaKey.config?.inputType === "checkbox" ?
                                        <>
                                            {multipleChoiceValues && multipleChoiceValues.length ?
                                                null
                                                :
                                                <Input type='hidden' name={name} />
                                            }
                                            <CheckboxGroup onChange={e => setMultipleChoiceValues(e)} size={"sm"} w="100%">
                                                {map(metaKey.config?.options, opt => (
                                                    <Checkbox name={name + "[]"} mr={2} value={opt}>{opt}</Checkbox>
                                                ))}
                                            </CheckboxGroup>
                                        </>

                                        : metaKey.config?.inputType === "multiSelect" ?
                                            <>
                                                <Multiselect

                                                    value={multipleSelectValues}
                                                    name={name}
                                                    placeholder="select"
                                                    data={metaKey.config?.options}
                                                    onChange={d => setMultipleSelectValues(d)}
                                                    // textField={metaKey.config?.options}
                                                    required={required}
                                                />
                                                {multipleSelectValues?.length ?
                                                    map(multipleSelectValues, val => (
                                                        <Input type="hidden" name={name + "[]"} value={val} />
                                                    ))
                                                    :
                                                    <Input type="hidden" name={name + "[]"} value={null} />
                                                }
                                            </>
                                            :
                                            null
                                    }
                                </>
                                : metaKey.data_type === META_KEY_TYPES.DATE ?
                                    <Flex w="100%">
                                        {/* <DatePicker
                                            
                                            name={name}
                                            style={{ width: "100%" }}
                                            date={true}
                                            time={false}
                                            format='DD-MM-YYYY'
                                            dropDown={true}
                                        /> */}
                                        {/* <CustomDatePicker getDate={date => setDateField(date)} />
                                        <Input type='hidden' name={name} value={dateField} required /> */}
                                        <Input type="date" name={name} />
                                    </Flex>
                                    : metaKey.data_type === META_KEY_TYPES.BOOLEAN ?
                                        metaKey.config?.inputType === "switch" ?
                                            <Switch name={name} />
                                            :
                                            <Checkbox name={name} />
                                        : metaKey.data_type === META_KEY_TYPES.NUMBER ?
                                            <Input type='number' maxLength={metaKey.config?.textLimit} size={"sm"} name={name} placeholder={metaKey.key} />
                                            :
                                            null
                }
            </FormControl >
            :
            null
    )
}
